import styles from "./PurchaseInfo.module.scss";
import PurchaseInfoController, { PurchaseInfoProps } from "./PurchaseInfo.controller";


export const availablePaymentMethods = [
    'Visa',
    'Bizum',
];

const PurchaseInfo = ({ description, feature, cost, taxes, total, paymentMethod, resourceId, updateUser, setPaymentMethod, fromSpain, discountId }: PurchaseInfoProps) => {

    const {
        handlePaymentMethodChange,
        getPaymentMethodIcon,
    } = PurchaseInfoController({ total, paymentMethod, feature, setPaymentMethod, updateUser, resourceId, discountId } as PurchaseInfoProps)

    return (
        <div className={styles.container}>
            <h2>Resumen del pedido</h2>
            <div className={styles.description}>
                <p>{description}</p>
            </div>
            <hr />
            <div className={styles.cost}>
                <label>Coste del servicio:</label>
                <p>{cost}€</p>
            </div>
            <div className={styles.taxes}>
                <label>Tasas:</label>
                <p>{taxes}€</p>
            </div>
            <div className={styles.total}>
                <label>Total:</label>
                <p>{total}€</p>
            </div>
            <hr />
            <h2>Selecciona el método de pago</h2>
            <div className={styles.paymentContainer}>
                <div className={styles.payment}>
                    {availablePaymentMethods.map((method, index) => {
                        if (method === 'Bizum' && !fromSpain) {
                            return null;
                        }
                        return (
                            <div key={index} className={styles.paymentCheckbox}>
                                {<button
                                    type="button"
                                    key={method}
                                    className={`${styles.paymentMethodButton} ${method === paymentMethod ? styles.buttonBorder : ''}`}
                                    onClick={() => handlePaymentMethodChange(method)}
                                >
                                    {getPaymentMethodIcon(method)}
                                </button>}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    )
}

export default PurchaseInfo;
