import { FunctionComponent, SVGAttributes } from 'react';
export enum ButtonVariants {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    TERCIARY = 'terciary',
}
export enum ButtonSizes {
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large',
}
export interface ButtonProps {
    children?: React.ReactNode;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    disabled?: boolean;
    type?: 'submit' | 'reset' | 'button' | undefined;
    form?: string;
    IconLeft?: FunctionComponent<SVGAttributes<SVGElement>> | any;
    IconRight?: FunctionComponent<SVGAttributes<SVGElement>> | any;
    variant: ButtonVariants;
    size: ButtonSizes;
}
