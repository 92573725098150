import styles from './Footer.module.scss';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';


const Footer = () => {
    const location = useLocation();
    const isHomePage = location.pathname === '/';

    const isBiggerThanPortraitTablet = useMediaQuery({
        query: '(min-width: 768px)'
    });

    return (
        <div className={`${styles.container} ${isHomePage ? styles.footerPrimary : styles.footerSecondary}`}>
            <div className={`${styles.contact} ${isHomePage ? styles.footerTextSecondary : styles.footerTextPrimary}`}>
                <div className={styles.contactInsideContainer}>
                    <a href={"mailto:" + process.env.CONTACT_EMAIL}>
                        <i className="fas fa-envelope"></i>
                        <span>
                            {process.env.CONTACT_EMAIL}
                        </span>
                    </a>
                    {/* <a href="tel:968888888">
                        <i className="fas fa-phone-alt"></i>
                        <span>
                            968 888 888
                        </span>
                    </a> */}
                </div>
            </div>

            <div className={`${styles.links} ${isHomePage ? styles.footerTextSecondary : styles.footerTextPrimary}`}>
                <div className={styles.linksInsideContainer}>
                    <a href={process.env.POLITICA_PRIVACIDAD_URL}>
                        Política de privacidad
                    </a>
                    {isBiggerThanPortraitTablet && <span> | </span>}
                    <a href={process.env.POLITICA_COOKIES_URL}>
                        Política de cookies
                    </a>
                    {isBiggerThanPortraitTablet && <span> | </span>}
                    <a href={process.env.AVISO_LEGAL_URL}>
                        Aviso legal
                    </a>
                    {isBiggerThanPortraitTablet && <span> | </span>}
                    <a href={process.env.PROPIEDAD_INTELECTUAL_URL}>
                        Propiedad intelectual
                    </a>
                </div>
            </div>


            <div className={`${styles.rights} ${isHomePage ? styles.footerTextSecondary : styles.footerTextPrimary}`}>
                <div className={styles.rightsInsideContainer}>
                    <span>
                        Creado por <a href={process.env.CREADO_POR_URL}>{process.env.CREADO_POR}</a>
                    </span>
                    <span>
                        Todos los derechos reservados. SANAI 2023
                    </span>
                </div>
            </div>
        </div>
    );
};

export default Footer;
